"use client";

import Lottie from "lottie-react";

export default function PublicLottieAnimation({ animation }) {
    return (
        <Lottie
            animationData={animation}
            autoplay={true}
            loop={true}
            style={{ width: "100%" }}
        />
    );
}
